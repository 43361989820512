export const shuffle = array => {
  const copyArray = JSON.parse(JSON.stringify(array));
  for (let i = copyArray.length - 1; i > 0; i--) {
    // get random index from 0 to i
    let randomIndex = Math.floor(Math.random() * (i + 1));
    // swap element at that index w/ current index
    const temp = copyArray[randomIndex];
    copyArray[randomIndex] = copyArray[i];
    copyArray[i] = temp;
  }
  return copyArray;
};
