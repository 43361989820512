import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Regular } from '../components/text';

const CircleLetter = styled(Regular).attrs(props => ({ as: 'span' }))`
  height: 90px;
  position: absolute;
  width: 20px;
  left: 0;
  top: 0;
  transform-origin: bottom center;
  background: linear-gradient(171.86deg, #ff5980 1.46%, #ffa882 98.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Rotate = keyframes`
  from { 
    transform: rotate(0);
  }
  to { 
    transform: rotate(1turn);
  }
`;

const CircleLetters = styled.div`
  animation: 12s infinite ${Rotate} linear;
  position: absolute;
  right: 8%;
  bottom: 0;
  z-index: 1;
  width: 180px;
  height: 180px;
  max-width: 180px;
  max-height: 180px;
  @media ${props => props.theme.breakpoints.md} {
    bottom: -10%;
  }
  @media ${props => props.theme.breakpoints.xs} {
    right: -90px;
  }
`;

export const CircleText = ({ text, angleOffset, angleSpan }) => {
  const numLetters = text.length;
  const anglePerChar = angleSpan / numLetters;
  return (
    <CircleLetters>
      {text.split('').map((letter, i) => {
        const rotateAmount = angleOffset + anglePerChar * i;
        return (
          <CircleLetter
            key={i}
            css={`
              transform: translateX(80px) rotate(${rotateAmount}deg);
              font-size: ${letter === '•' ? '32px' : '21px'};
              line-height: ${letter === '•' ? '0.9' : null};
              font-weight: 700;
            `}>
            {letter}
          </CircleLetter>
        );
      })}
    </CircleLetters>
  );
};
