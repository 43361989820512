import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {HeaderLarge, Header, DisplayTiny, Large, HeaderSmall} from '../components/text';
import { navSections } from '../components/navbar';
import { CircleText } from '../components/CircleText';
import { Grid, Row, Col } from '../components/grid';
import { TeamMember, Investor } from '../components/peopleComponents';
import { shuffle } from '../utils/shuffle';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';

const MissionSection = styled(Grid).attrs(props => ({
  as: 'section',
}))`
  background: #eff1f6;
  padding-top: ${props => props.theme.spacing(17)};
  padding-bottom: ${props => props.theme.spacing(17)};
  @media ${props => props.theme.breakpoints.sm} {
    padding-top: ${props => props.theme.spacing(16)};
    padding-bottom: ${props => props.theme.spacing(17)};
    padding-right: ${props => props.theme.spacing(8)};
    padding-left: ${props => props.theme.spacing(8)};
  }
`;

const MissionText = styled.h1`
  width: ${props => props.theme.spacing(98)};
  font-weight: 600;
  font-size: ${props => props.theme.text.displayLarge.size};
  margin: 0 auto;
  line-height: 56px;
  text-align: center;
  background: linear-gradient(171.86deg, #ff5980 1.46%, #ffa882 98.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media ${props => props.theme.breakpoints.md} {
    width: 100%;
    font-size: ${props => props.theme.text.displayTiny.size};
  }
`;

const DescriptionSection = styled(Grid).attrs(props => ({ as: 'section' }))`
  position: relative;
  background: ${props => props.theme.color.grey10};
  padding-top: ${props => props.theme.spacing(12)};
  padding-bottom: ${props => props.theme.spacing(21)};
  & ${HeaderLarge} {
    margin-bottom: ${props => props.theme.spacing(8)};
  }
  @media ${props => props.theme.breakpoints.sm} {
    padding-top: ${props => props.theme.spacing(8)};
  }
`;

const DirectorsLink = styled(Link).attrs(props => ({ href: 'https://support.namebase.io/en/articles/6783507-did-namebase-make-handshake', target: '_blank' }))`
  color: ${props => props.theme.color.nbPrimary};
  transition: ${props => props.theme.transitions.color};
  &:hover {
    color: ${props => props.theme.color.nbPrimaryDark};
  }
`;

const WavySvg = styled.svg`
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  height: 10vw;
`;

const WavyBorder = () => (
  <WavySvg
    width="1440"
    height="106"
    viewBox="0 0 1440 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1440 106H410.351C410.234 106 410.117 106 410 106C409.841 106 409.681 106 409.522 106H0V26.0001C79.2631 53.9754 229.733 105.908 409.522 106H410.351C582.603 105.88 991.533 18.0148 1151 6.00013C1297 -4.99984 1397 5.00013 1440 6.00013V106Z"
      fill="white"
    />
  </WavySvg>
);

const TeamSection = styled(Grid).attrs(props => ({ as: 'section' }))`
  padding-top: ${props => props.theme.spacing(19)};
  margin-right: ${props => props.theme.spacing(18)};
  margin-left: ${props => props.theme.spacing(18)};
  @media ${props => props.theme.breakpoints.md} {
    margin-right: ${props => props.theme.spacing(4)};
    margin-left: ${props => props.theme.spacing(4)};
  }
  @media ${props => props.theme.breakpoints.sm} {
    margin-right: ${props => props.theme.spacing(1)};
    margin-left: ${props => props.theme.spacing(1)};
  }
`;

const TeamRows = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media ${props => props.theme.breakpoints.sm} {
    align-items: center;
    flex-direction: column;
  }
`;

const BarSpacer = styled.div`
  width: 60px;
  height: 4px;
  margin-top: ${props => props.theme.spacing(4)};
  margin-bottom: ${props => props.theme.spacing(14)};
  margin-left: auto;
  margin-right: auto;
  background: ${props => props.theme.gradients.redOrange};
`;

const Wrapper = styled.div`
  overflow: hidden;
`;

const About = ({ data }) => {
  const meta = usePageMetaAttributes('about');

  const teamMembers = data.allContentfulTeamMember.nodes.map(person => {
    return { ...person, headshot: person.headshot.file.url };
  });
  const investors = data.allContentfulInvestor.nodes;
  return (
    <Layout currentSection={navSections.RESOURCES}>
      <SEO {...meta} />
      <Wrapper css={{ paddingBottom: '150px' }}>
        <MissionSection>
          <MissionText>Our mission is to enable freedom and safety on the internet</MissionText>
        </MissionSection>
        <DescriptionSection>
          <Row>
            <Col lg={5} lgOffset={1} md={10}>
              <Header css={{ fontWeight: 500 }}>
                We didn't create Handshake, but just like you are, we are{' '}
                <DirectorsLink>Directors of Handshake.</DirectorsLink>
                <br />
                <br />
              </Header>
              <HeaderSmall>
                Handshake will create an Internet that’s more secure, censorship-resistant,
                seizure-resistant, private, and truly-owned by individuals not corporations.
                <br />
                <br />
                Our goal is to make Handshake easy-to-use to enable mass adoption.
              </HeaderSmall>
            </Col>
          </Row>
          <CircleText text="NAMEBASE•HANDSHAKE•" angleOffset={-100} angleSpan={360} />
          <WavyBorder />
        </DescriptionSection>
        <TeamSection>
          <DisplayTiny align="center">Namebase Team</DisplayTiny>
          <BarSpacer />
          <TeamRows>
            {shuffle(teamMembers).map((member, i) => {
              const { name, role, background, headshot } = member;
              return (
                <TeamMember
                  key={name}
                  name={name}
                  role={role}
                  i={i}
                  background={background.background}
                  headshot={headshot}
                />
              );
            })}
          </TeamRows>
        </TeamSection>
        <TeamSection>
          <DisplayTiny align="center">Investors & Advisors</DisplayTiny>
          <BarSpacer />
          <TeamRows>
            {investors.map((investor, i) => {
              const { headshot, name, firm } = investor;
              return (
                <Investor key={name} name={name} i={i} firm={firm} headshot={headshot.file.url} />
              );
            })}
          </TeamRows>
        </TeamSection>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    allContentfulTeamMember {
      nodes {
        name
        role
        background {
          background
        }
        headshot {
          file {
            url
          }
          description
        }
      }
    }
    allContentfulInvestor {
      nodes {
        name
        firm
        headshot {
          file {
            url
          }
          description
        }
      }
    }
  }
`;

export default About;
