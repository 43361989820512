import React, { createRef, useEffect } from 'react';
import styled from 'styled-components';
import {Medium, Small} from '../components/text';
import {Col, Row} from '../components/grid';

const GradientDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-top: ${props => props.theme.spacing(3)};
  margin-bottom: ${props => props.theme.spacing(1.5)};
  background: ${props => props.theme.gradients.redOrange};
`;

const HeadshotImage = styled.img`
  width: 100%;
  max-width: 160px;
  border-radius: 100%;
  filter: brightness(97%);
`;

const TeamMemberWrapper = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  margin-left: ${props => props.theme.spacing(6)};
  margin-right: ${props => props.theme.spacing(6)};
  margin-bottom: ${props => props.theme.spacing(8)};
  flex-basis: 20%;
  max-width: ${props => props.theme.spacing(25)};

  @media ${props => props.theme.breakpoints.md} {
    max-width: ${props => props.theme.spacing(30)};
  }
  & .role {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

export const TeamMember = ({ name, role, i, headshot, background }) => {
  const imageRef = createRef();
  useEffect(() => {
    if (window === 'undefined') {
      // do nothing
    } else {
      imageRef.current.src = headshot;
      imageRef.current.alt = name;
    }
  }, []);

  return (
    <TeamMemberWrapper>
      <HeadshotImage src={headshot} alt={name} ref={imageRef} />
      <GradientDot />
      <Medium align="center" weight="medium">
        {name}
      </Medium>
      <Small align="center" className="role" css={{ opacity: 0.7 }}>
        {role}
      </Small>
      {/*<Small align="center" css={{ opacity: 0.5, fontSize: '13px', lineHeight: 1.3 }}>{background}</Small>*/}
    </TeamMemberWrapper>
  );
};

export const Investor = ({ name, i, firm, headshot }) => {
  return (
    <TeamMemberWrapper>
      <HeadshotImage src={headshot} alt={name} />
      <GradientDot />
      <Medium align="center" weight="medium" css={{ opacity: 0.7 }}>
        {name}
      </Medium>
      <Small align="center" css={{ opacity: 0.5, fontSize: '13px', lineHeight: 1.3 }}>{firm}</Small>
    </TeamMemberWrapper>
  );
};
